// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nominate-athlete-js": () => import("./../../../src/pages/nominate-athlete.js" /* webpackChunkName: "component---src-pages-nominate-athlete-js" */),
  "component---src-pages-nominate-coach-js": () => import("./../../../src/pages/nominate-coach.js" /* webpackChunkName: "component---src-pages-nominate-coach-js" */),
  "component---src-pages-recognition-js": () => import("./../../../src/pages/recognition.js" /* webpackChunkName: "component---src-pages-recognition-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-winner-js": () => import("./../../../src/templates/winner.js" /* webpackChunkName: "component---src-templates-winner-js" */)
}

